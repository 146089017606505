<template>
 <tbody class="flex-1 sm:flex-none divide-y divide-gray-200">
  <tr
   v-for="(ticket, ticketIdx) in this.tablePaginatedData"
   :key="ticket.id"
   :class="[
    ticketIdx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
    'flex flex-col sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
   ]"
   v-show="
    (ticket.open == 1 && this.$route.path === '/tickets') ||
    (ticket.open == 0 && this.$route.path === '/closed-tickets')
   "
  >
   <td
    @click="goToDetail(ticket.id, ticket.status_id)"
    class="p-3 text-sm font-medium text-gray-900"
    v-if="!removedHeaders.includes('ticketTable.subject')"
   >
    <div class="inline-block">
     <div>
      {{ ticket.subject }}
      <span class="block 2xl:hidden text-xs text-gray-700 mr-1"> [#{{ ticket.id }}] </span>
     </div>
     <div class="hidden 2xl:inline-flex text-gray-500">
      <div class="flex gap-1">
       <span class="no-underline font-medium"> #{{ ticket.id }} </span>
       <span class="hidden 2xl:block"> • </span>
       <p class="hidden 2xl:flex">
        {{ getTimeAgo("created", ticket?.created_at) }}
       </p>
      </div>
     </div>
    </div>
   </td>
   <td
    class="p-3 truncate"
    @click="goToDetail(ticket.id, ticket.status_id)"
    v-if="!removedHeaders.includes('ticketTable.status') && ticket.open"
   >
    <span
     :class="{
      encom_ouvert_ticket_new: ticket.status_id == 1,
      encom_en_traitement_ticket_new: ticket.status_id == 2,
      encom_en_attente_new: ticket.status_id == 3,
      encom_autre_ticket_new: ticket.status_id == 4,
     }"
     class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium"
    >
     {{ checkStatus(ticket.status_id) }}
    </span>

    <!--  <div class="mt-0 flex items-center text-white">
     <p
      :class="[
       ticket.status_id == 1
        ? `encom_ouvert_ticket px-2 inline-flex text-xs leading-5 font-semibold rounded-full`
        : ticket.status_id == 2
        ? `encom_en_traitement_ticket px-2 inline-flex text-xs leading-5 font-semibold rounded-full`
        : ticket.status_id == 3
        ? `encom_pink_bg px-2 inline-flex text-xs leading-5 font-semibold rounded-full`
        : `encom_closed_ticket px-2 inline-flex text-xs leading-5 font-semibold rounded-full`,
      ]"
     >
      {{ checkStatus(ticket.status_id) }}
     </p>
    </div> -->
   </td>
   <!-- <td
        class="py-3 truncate"
        v-if="!removedHeaders.includes('ticketTable.priority')"
        @click="goToDetail(ticket.id, ticket.status_id)"
      >
        <div class="mt-0 flex items-center text-white">
          <p
            :class="[
              ticket.priority_id == 1
                ? `inline-flex items-center px-2 py-0.5 sm:ml-5 rounded-full text-xs font-medium bg-blue-100 text-blue-800`
                : ticket.priority_id == 2
                ? `inline-flex items-center px-2 py-0.5 sm:ml-5 rounded-full text-xs font-medium bg-green-100 text-green-800`
                : ticket.priority_id == 3
                ? `inline-flex items-center px-2 py-0.5 sm:ml-5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800`
                : `inline-flex items-center px-2 py-0.5 sm:ml-5 rounded-full text-xs font-medium bg-red-100 text-red-800`,
            ]"
          >
            {{ checkPriority(ticket.priority_id) }}
          </p>
        </div>
      </td> -->
   <td
    class="p-3 truncate text-sm text-gray-500 capitalize"
    @click="goToDetail(ticket.id, ticket.status_id)"
    v-if="!removedHeaders.includes('ticketTable.createdDate')"
   >
    {{ $d(ticket.created_at, "longNumb") }}
   </td>
   <td
    class="p-3 truncate text-sm text-gray-500 capitalize"
    @click="goToDetail(ticket.id, ticket.status_id)"
    v-if="!removedHeaders.includes('ticketTable.updatedDate')"
   >
    {{ $d(ticket.updated_at, "longNumb") }}
   </td>
   <td
    class="p-3 truncate text-sm text-gray-500 capitalize"
    @click="goToDetail(ticket.id, ticket.status_id)"
    v-if="!removedHeaders.includes('ticket.resolution')"
   >
    {{ $d(ticket.estimatedResolutionDate, "longNumb") }}
   </td>
   <td class="p-3 truncate text-sm text-white text-center">
    <button
    v-if="
      userPermissions.permissions.includes('ticketing.*') ||
      userPermissions.permissions.includes('ticketing.create')
     "
     type="button"
     @click="
      this.openOrCloseTicket(
       ticket.open,
       ticket.id,
       $t('ticketTable.confirmationOpenMsg'),
       $t('ticketTable.confirmationCloseMsg'),
       $t('ticketTable.reopenMsg'),
       $t('ticketTable.closeMsg'),
       $t('ticketTable.successMsg'),
       $t('ticketTable.dialogTitleOpen'),
       $t('ticketTable.dialogTitleClose'),
       $t('dialog.confirm'),
       $t('dialog.goBack')
      )
     "
     :class="[
      ticket.open == 0
       ? 'hover:bg-green-50 hover:text-green-500 hover:ring-green-500'
       : 'hover:bg-red-50 hover:text-red-500 hover:ring-red-500',
      'hover:ring-inset hover:ring-1  text-gray-700 relative -ml-px inline-flex items-center rounded-md bg-white px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
     ]"
     :title="ticket.open == 0 ? $t('reopenTicket') : $t('closeTicket')"
     @mouseenter="hoveredTicketId = ticket.id"
     @mouseleave="hoveredTicketId = null"
    >
     <svg
      v-if="ticket.open === 1 && hoveredTicketId === ticket.id"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      class="w-5 h-5"
     >
      <path
       fill-rule="evenodd"
       d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
       clip-rule="evenodd"
      />
     </svg>
     <svg
      v-else-if="ticket.open !== 1 && hoveredTicketId === ticket.id"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      class="w-5 h-5"
     >
      <path
       fill-rule="evenodd"
       d="M14.5 1A4.5 4.5 0 0010 5.5V9H3a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-1.5V5.5a3 3 0 116 0v2.75a.75.75 0 001.5 0V5.5A4.5 4.5 0 0014.5 1z"
       clip-rule="evenodd"
      />
     </svg>
     <svg
      v-else-if="ticket.open === 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      class="w-5 h-5"
     >
      <path
       fill-rule="evenodd"
       d="M14.5 1A4.5 4.5 0 0010 5.5V9H3a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-1.5V5.5a3 3 0 116 0v2.75a.75.75 0 001.5 0V5.5A4.5 4.5 0 0014.5 1z"
       clip-rule="evenodd"
      />
     </svg>
     <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      class="w-5 h-5"
     >
      <path
       fill-rule="evenodd"
       d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
       clip-rule="evenodd"
      />
     </svg>
    </button>
    <!--  <button
 
     v-if="
      userPermissions.permissions.includes('ticketing.*') ||
      userPermissions.permissions.includes('ticketing.create')
     "
 
     @click="
      this.openOrCloseTicket(
       ticket.open,
       ticket.id,
       $t('ticketTable.confirmationOpenMsg'),
       $t('ticketTable.confirmationCloseMsg'),
       $t('ticketTable.reopenMsg'),
       $t('ticketTable.closeMsg'),
       $t('ticketTable.successMsg'),
       $t('ticketTable.dialogTitleOpen'),
       $t('ticketTable.dialogTitleClose'),
       $t('dialog.confirm'),
       $t('dialog.goBack')
      )
     "
     :class="[
      ticket.open == 0 ? 'body__table--green-button' : 'body__table--red-button',
      'cursor-pointer rounded px-2 w-auto',
     ]"
     :title="ticket.open == 0 ? $t('reopenTicket') : $t('closeTicket')"
    >
     <div class="inline-flex p-1" v-if="ticket.open == 0">
      <span>{{ $t("ticketTable.reopenTicket") }}</span>
     </div>
     <div class="inline-flex p-1" v-if="ticket.open == 1">
      <span>{{ $t("ticketTable.closeTicket") }}</span>
     </div>
 
    </button> -->
 
   </td>
  </tr>
 </tbody>
</template>

<script>
 
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
 
import { mapGetters } from "vuex";
export default {
 props: [
  "openOrCloseTicket",
  "checkStatus",
  "status",
  "formatDate",
  "formatDateFrench",
  "checkPriority",
  "checkStatusColor",
  "tablePaginatedData",
  "account",
  "removedHeaders",
  "goToDetail",
 ],
 components: {},
 data() {
  return {
   priority: [
    { id: 1, name: "Basse" },
    { id: 2, name: "Normale" },
    { id: 3, name: "Haute" },
    { id: 4, name: "Critique" },
   ],
   hoveredTicketId: null,
   userPermissions,
  };
 },
 methods: {
  checkStatusColor(id) {
   if (this.status) {
    for (let index = 0; index < this.status.length; index++) {
     if (this.status[index].id == id) {
      return this.status[index].color;
     }
    }
   }
  },
  checkPriority(id) {
   if (this.priority) {
    for (let index = 0; index < this.priority.length; index++) {
     if (this.priority[index].id == id) {
      return this.priority[index].name;
     }
    }
   }
  },
  getTimeAgo(dateType, date) {
   const seconds = Math.floor((new Date() - new Date(date)) / 1000);
   const intervals = {
    bulkYear: 31536000,
    bulkMonth: 2592000,
    bulkWeek: 604800,
    bulkDay: 86400,
    bulkHour: 3600,
    bulkMinute: 60,
    bulkSecond: 1,
   };

   for (const [interval, secondsInInterval] of Object.entries(intervals)) {
    const intervalCount = Math.floor(seconds / secondsInInterval);
    //créé il y a 3 semaines
    //mis à jour il y a 3 semaines
    if (intervalCount >= 1 && dateType === "created") {
     return this.$i18n.locale === "en"
      ? `created ${this.$tc(interval, intervalCount, {
         count: intervalCount,
        })} ago`
      : `créé il y a ${this.$tc(interval, intervalCount, {
         count: intervalCount,
        })}`;
    } else if (intervalCount >= 1 && dateType === "updated") {
     return this.$i18n.locale === "en"
      ? `Updated ${this.$tc(interval, intervalCount, {
         count: intervalCount,
        })} ago`
      : `Mis à jour il y a ${this.$tc(interval, intervalCount, {
         count: intervalCount,
        })}`;
    }
   }

   return this.$i18n.locale === "en" ? "just now" : "à l'instant";
  },
 
 },
 computed: {
  ...mapGetters(["active", "language"]),
 },
};
</script>

<style>
.ticket_width {
 min-width: 300px;
}
</style>

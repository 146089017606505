<template>
 <PBXLevelList
  v-if="this.$route.path === '/pbx-extension' || this.$route.path === '/pbx-callqueue'"
  :tablePaginatedData="this.pagination().querySet"
  :level="this.level"
 />
 <expert-statistics-level-list
  v-if="
   this.$route.path === '/expert-statistics-extension' ||
   this.$route.path === '/expert-statistics-callqueue'
  "
  :tablePaginatedData="this.pagination().querySet"
 />
 <ReportListTable
  v-if="
   this.$route.path === '/expert-statistics/reports/extension' ||
   this.$route.path === '/expert-statistics/reports/queue'
  "
  :tablePaginatedData="this.pagination().querySet"
  @sort="$emit('sort', $event)"
  @openEditForm="(...args) => $emit('openEditForm', ...args)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <InvoiceTable
  v-if="this.$route.path === '/invoices'"
  :account="this.account"
  :tablePaginatedData="this.pagination().querySet"
  @sort="$emit('sort', $event)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <TicketTable
  v-if="this.$route.path === '/tickets'"
  :tablePaginatedData="this.pagination().querySet"
  @sorting="$emit('sort', $event)"
  :statuses="statuses"
  :searchStatus="this.searchStatus"
  @pushStatusSearch="$emit('pushStatusSearch', $event)"
  @clearStatusFilter="$emit('clearStatusFilter')"
  :searchPriority="this.searchPriority"
  @pushPrioritySearch="$emit('pushPrioritySearch', $event)"
  @clearPriorityFilter="$emit('clearPriorityFilter')"
  @getTickets="$emit('getTickets')"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
  @updateTicketDetail="(...args) => $emit('updateTicketDetail', ...args)"
 />
 <TicketClosedTable
  v-if="this.$route.path === '/closed-tickets'"
  :tablePaginatedData="this.pagination().querySet"
  @sorting="$emit('sort', $event)"
  :statuses="statuses"
  :searchStatus="this.searchStatus"
  @pushStatusSearch="$emit('pushStatusSearch', $event)"
  @clearStatusFilter="$emit('clearStatusFilter')"
  :searchPriority="this.searchPriority"
  @pushPrioritySearch="$emit('pushPrioritySearch', $event)"
  @clearPriorityFilter="$emit('clearPriorityFilter')"
  @getTickets="$emit('getTickets')"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
  @updateTicketDetail="(...args) => $emit('updateTicketDetail', ...args)"
 />
 <DocumentsTable
  v-if="this.$route.path.includes('/documents')"
  :account="this.account"
  :headerTitle="documentHeaderName"
  :tablePaginatedData="this.pagination().querySet"
  @setLoading="$emit('setLoading', $event)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <ContactTable
  v-if="this.$route.path === '/contacts-routes' && !this.preview"
  :tablePaginatedData="this.pagination().querySet"
  @sort="$emit('sort', $event)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <ContactTablePreview
  v-if="this.$route.path === '/contacts-routes' && this.preview"
  :previewDataPaginated="this.pagination().querySet"
  :previewData="this.previewData"
  :previewTableHeader="this.previewTableHeader"
  previewTableType="contacts"
 />
 <CTIsList
  v-if="this.$route.path === '/cti'"
  :tablePaginatedData="this.pagination().querySet"
  @openEditForm="(...args) => $emit('openEditForm', ...args)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <RoutingLists
  v-if="this.$route.path === '/georouting'"
  :account="this.account"
  :tablePaginatedData="this.pagination().querySet"
  @openEditForm="(...args) => $emit('openEditForm', ...args)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <CallQueuesTable
  v-if="this.$route.path === '/callqueues'"
  :account="this.account"
  :tablePaginatedData="this.pagination().querySet"
  @sort="$emit('sort', $event)"
  @openEditForm="(...args) => $emit('openEditForm', ...args)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <CallQueuesDetailTable
  v-if="this.$route.path.includes('/callqueues/')"
  :account="this.account"
  :tablePaginatedData="this.pagination().querySet"
  :callqueueTable="this.callqueueTable"
  @sortTimeSpans="$emit('sortCallQueuesTimeSpans', $event)"
  @sortExceptions="$emit('sortExceptions', $event)"
  @openEditFormTimeSlots="$emit('openEditFormTimeSlots', $event)"
  @openEditFormExceptions="(...args) => $emit('openEditFormExceptions', ...args)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
  :ruleHasGroup="ruleHasGroup"
 />
 <CallQueuesGroupsTable
  v-if="this.$route.path === '/callqueues-groups'"
  :account="this.account"
  :tablePaginatedData="this.pagination().querySet"
  @sort="$emit('sort', $event)"
  @openEditForm="(...args) => $emit('openEditForm', ...args)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <CallQueuesGroupsDetailTable
  v-if="this.$route.path.includes('/callqueues-groups/')"
  :account="this.account"
  :callqueueTable="this.callqueueTable"
  :tablePaginatedData="this.pagination().querySet"
  @sortTimeSpans="$emit('sortCallQueuesTimeSpans', $event)"
  @sortExceptions="$emit('sortExceptions', $event)"
  @openEditFormTimeSlots="$emit('openEditFormTimeSlots', $event)"
  @openEditFormExceptions="(...args) => $emit('openEditFormExceptions', ...args)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <CTIDestinationsTable
  v-if="this.$route.path.includes('/cti-detail') && !this.preview"
  :tablePaginatedData="this.pagination().querySet"
  :id="this.id"
  @openEditForm="(...args) => $emit('openEditForm', ...args)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <ContactTablePreview
  v-if="this.$route.path.includes('/cti-detail') && this.preview"
  :previewDataPaginated="this.pagination().querySet"
  :previewData="this.previewData"
  :previewTableHeader="this.previewTableHeader"
  previewTableType="cti-destination"
  :id="this.id"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <GroupsList
  v-if="
   this.$route.path === '/expert-statistics/groups/extension' ||
   this.$route.path === '/expert-statistics/groups/queue'
  "
  :tablePaginatedData="this.pagination().querySet"
  :elementsData="elementsData"
  @openEditForm="(...args) => $emit('openEditForm', ...args)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <RecipientsTable
  v-if="this.$route.name === 'Recipients'"
  :tablePaginatedData="this.pagination().querySet"
  :activeTab="activeTab"
  :pivots="this.pivots"
  @openEditForm="(...args) => $emit('openEditForm', ...args)"
  @sort="$emit('sort', $event)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <CampaignsTable
  v-if="this.$route.name === 'SMSCampaign' && activeTab === 'campaign'"
  :account="this.account"
  :activeTab="activeTab"
  :tablePaginatedData="this.pagination().querySet"
  @openEditForm="(...args) => $emit('openEditForm', ...args)"
  @sort="$emit('sort', $event)"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)"
 />
 <nav
  class="bg-white px-4 py-3 flex flex-wrap items-center justify-between border-t border-gray-200 sm:px-6"
  aria-label="Pagination"
 >
  <div class="hidden sm:block">
   <p class="text-sm text-gray-700">
    {{ $t("ticketTable.showing") }}
    {{ " " }}
    <span class="font-medium">{{ this.rows * this.page - this.rows + 1 }}</span>
    {{ " " }}
    {{ $t("ticketTable.to") }}
    {{ " " }}
    <span class="font-medium">{{ getPageLength() }} </span>
    {{ " " }}
    {{ $t("ticketTable.of") }}
    {{ " " }}
    <span class="font-medium">{{ this.tableData.length }}</span>
    {{ " " }}
    {{ $t("ticketTable.results") }}
   </p>
  </div>
  <div class="sm:px-10">
   <select
    @change="setRows($event)"
    id="location"
    name="location"
    class="rounded mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm"
   >
    <option :value="this.rows">{{ $t("ticketTable.selectRows") }}</option>
    <option v-for="numb in this.selectRows" :key="numb" :value="numb">
     {{ numb }} {{ $t("ticketTable.rows") }}
    </option>
    <option :value="this.tableData.length">{{ $t("ticketTable.showAll") }}</option>
   </select>
  </div>
  <div class="flex-1 flex sm:justify-end pt-3 sm:pt-0">
   <a
    href="javascript:void(0)"
    @click="this.page = 1"
    class="rounded relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table--header"
   >
    <svg
     xmlns="http://www.w3.org/2000/svg"
     class="h-4 w-4"
     fill="none"
     viewBox="0 0 24 24"
     stroke="currentColor"
    >
     <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
     />
    </svg>
   </a>
   <a
    href="javascript:void(0)"
    @click="previousPage"
    class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table--header"
   >
    {{ $t("ticketTable.previous") }}
   </a>

   <a
    href="javascript:void(0)"
    @click="nextPage"
    class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table--header"
   >
    {{ $t("ticketTable.next") }}
   </a>
   <a
    href="javascript:void(0)"
    @click="this.page = this.pagination().pages"
    class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table--header"
   >
    <svg
     xmlns="http://www.w3.org/2000/svg"
     class="h-4 w-4"
     fill="none"
     viewBox="0 0 24 24"
     stroke="currentColor"
    >
     <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M13 5l7 7-7 7M5 5l7 7-7 7"
     />
    </svg>
   </a>
  </div>
 </nav>
</template>

<script>
import CampaignsTable from "./SMS/CampaignsTable.vue";
import RecipientsTable from "./SMS/RecipientsTable.vue";
import PBXLevelList from "../components/PBXLevelList.vue";
import InvoiceTable from "../components/InvoiceTable.vue";
import TicketTable from "../components/TicketTable.vue";
import TicketClosedTable from "../components/TicketClosedTable.vue";
import DocumentsTable from "../components/DocumentsTable.vue";
import ContactTable from "../components/ContactTable.vue";
import ContactTablePreview from "../components/ContactTablePreview.vue";
import CTIsList from "../components/CTIsList.vue";
import RoutingLists from "../components/RoutingLists";
import CTIDestinationsTable from "../components/CTIDestinationsTable.vue";
import CallQueuesTable from "../components/CallQueuesTable.vue";
import CallQueuesDetailTable from "../components/CallQueuesDetailTable.vue";
import CallQueuesGroupsTable from "../components/CallQueuesGroupsTable.vue";
import CallQueuesGroupsDetailTable from "../components/CallQueuesGroupsDetailTable.vue";
import ExpertStatisticsLevelList from "../components/PBX/ExpertStatisticsLevelList";
import GroupsList from "../components/PBX/GroupsList";
import ReportListTable from "../components/PBX/ReportListTable.vue";

const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");

export default {
 name: "TablePagination",
 props: [
  "callqueueTable",
  "categoryId",
  "documentHeaderName",
  "id",
  "level",
  "openInfraView",
  "outTickets",
  "preview",
  "previewData",
  "previewTableHeader",
  "searchStatus",
  "searchPriority",
  "statuses",
  "tableData",
  "ticket_id",
  "elementsData",
  "activeTab",
  "ruleHasGroup"
 ],
 components: {
  PBXLevelList,
  InvoiceTable,
  TicketTable,
  TicketClosedTable,
  DocumentsTable,
  ContactTable,
  CTIsList,
  ContactTablePreview,
  RoutingLists,
  CTIDestinationsTable,
  CallQueuesTable,
  CallQueuesDetailTable,
  CallQueuesGroupsTable,
  CallQueuesGroupsDetailTable,
  ExpertStatisticsLevelList,
  GroupsList,
  ReportListTable,
  RecipientsTable,
  CampaignsTable,
 },
 data() {
  return {
   account,
   page: 1,
   rows: 20,
   selectedRows: null,
   selectRows: [5, 10, 15, 20, 25, 30],
  };
 },
 methods: {
  changePage(event) {
   this.page = event.target.value;
  },
  nextPage() {
   if (this.page < this.pagination().pages) {
    this.page = parseInt(this.page) + 1;
   } else {
    this.page = this.page;
   }
  },
  previousPage() {
   if (this.page > 1) {
    this.page = parseInt(this.page) - 1;
   } else {
    this.page = this.page;
   }
  },
  getPageLength() {
   if (this.page === this.pagination().pages) {
    return this.tableData.length;
   } else {
    return this.rows * this.page;
   }
  },
  setRows(event) {
   this.selectedRows = event.target.value;
  },
  pagination() {
   if (this.selectedRows) {
    this.rows = this.selectedRows;
   }
   let trimStart = (this.page - 1) * parseInt(this.rows);
   let trimEnd = trimStart + parseInt(this.rows);

   let trimmedData = this.tableData.slice(trimStart, trimEnd);

   let pages = Math.ceil(this.tableData.length / this.rows);

   return {
    querySet: trimmedData,
    pages: pages,
   };
  },
 },
};
</script>

<style></style>

<template>
 <!-- Activity list (smallest breakpoint only) -->
 <div class="sm:hidden">
  <ul role="list" class="divide-y divide-gray-200 overflow-hidden sm:hidden">
   <li
    v-for="ticket in this.tablePaginatedData"
    :key="ticket.id"
    class="px-4 py-4 bg-white hover:bg-gray-50 flex flex-col flex-no wrap sm:table-row sm:mb-0 cursor-pointer"
   >
    <a @click="goToDetail(ticket.id, ticket.status_id)" class="block">
     <span class="flex items-center space-x-4">
      <span class="flex-1 flex space-x-2 truncate">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
       >
        <path
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
        />
       </svg>
       <span class="flex flex-col text-gray-500 text-sm truncate">
        <span class="truncate font-semibold"
         >{{ ticket.subject }}
         <span
          :class="[
           ticket.status_id == 1
            ? 'encom_ouvert_ticket'
            : ticket.status_id == 2
            ? 'encom_en_traitement_ticket'
            : ticket.status_id == 3
            ? 'encom_pink_bg'
            : 'encom_closed_ticket',
           'text-white px-2 inline-flex text-xs leading-5 font-semibold rounded-full',
          ]"
         >
          {{ checkStatus(ticket.status_id) }}
         </span>
        </span>

        <time v-if="this.language === 'en'" :datetime="ticket.created_at"
         >{{ $t("ticketTable.createdAt") }} {{ formatDate(ticket.created_at) }}</time
        >
        <time v-if="this.language === 'fr'" :datetime="ticket.created_at"
         >{{ $t("ticketTable.createdAt") }} {{ formatDateFrench(ticket.updated_at) }}</time
        >
       </span>
      </span>

      <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
     </span>
    </a>
    <button
     @click="
      this.openOrCloseTicket(
       ticket.open,
       ticket.id,
       $t('ticketTable.confirmationOpenMsg'),
       $t('ticketTable.confirmationCloseMsg'),
       $t('ticketTable.reopenMsg'),
       $t('ticketTable.closeMsg'),
       $t('ticketTable.successMsg'),
       $t('ticketTable.dialogTitleOpen'),
       $t('ticketTable.dialogTitleClose'),
       $t('dialog.confirm'),
       $t('dialog.goBack')
      )
     "
     class="ml-7 mt-1 cursor-pointer rounded px-2 w-24 sm:w-40 text-gray-800 border bg-gray-50"
    >
     <div class="inline-flex py-1.5 text-xs" v-if="ticket.open == 0">
      <span class="flex">
       <LockOpenIcon class="flex-shrink-0 h-4 w-4 mr-0.5 encom_pink_text" aria-hidden="true" />
       {{ $t("ticketTable.reopenTicket") }}
      </span>
     </div>
     <div class="inline-flex py-1.5 text-xs" v-if="ticket.open == 1">
      <LockClosedIcon
       class="flex-shrink-0 h-4 w-4 mr-0.5 encom_closed_ticket_text"
       aria-hidden="true"
      />
      <span>{{ $t("ticketTable.closeTicket") }}</span>
     </div>
    </button>
   </li>
  </ul>
 </div>
 <!-- Table -->
 <div class="mt-8 flow-root">
  <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
   <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
     <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
       <tr class="flex flex-col flex-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0">
        <th class="p-3 text-left text-sm" v-for="header in tableHeaders" :key="header.name">
         <div
          v-if="header.name === 'ticketTable.status' || header.name === 'ticketTable.priority'"
          :class="[
           header.name === 'ticketTable.updatedDate' ? 'py-3' : '',
           'inline-flex sm:mt-0 cursor-pointer truncate',
          ]"
         >
          <div class="inline-flex" v-if="header.name === 'ticketTable.status'">
           <Menu as="div" class="mt-0 inline-block text-left">
            <div class="rounded">
             <MenuButton
              class="p-1 group inline-flex justify-center items-center space-x-1 text-sm font-semibold"
             >
              <p>
               {{ $t("ticketTable.status") }}
              </p>
              <svg
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20"
               fill="currentColor"
               class="h-4 w-4"
              >
               <path
                fill-rule="evenodd"
                d="M2.628 1.601C5.028 1.206 7.49 1 10 1s4.973.206 7.372.601a.75.75 0 01.628.74v2.288a2.25 2.25 0 01-.659 1.59l-4.682 4.683a2.25 2.25 0 00-.659 1.59v3.037c0 .684-.31 1.33-.844 1.757l-1.937 1.55A.75.75 0 018 18.25v-5.757a2.25 2.25 0 00-.659-1.591L2.659 6.22A2.25 2.25 0 012 4.629V2.34a.75.75 0 01.628-.74z"
                clip-rule="evenodd"
               />
              </svg>
             </MenuButton>
            </div>
            <transition
             enter-active-class="transition ease-out duration-100"
             enter-from-class="transform opacity-0 scale-95"
             enter-to-class="transform opacity-100 scale-100"
             leave-active-class="transition ease-in duration-75"
             leave-from-class="transform opacity-100 scale-100"
             leave-to-class="transform opacity-0 scale-95"
            >
             <MenuItems
              class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-20 px-1"
             >
              <div class="py-1">
               <MenuItem v-for="status in status" :key="status.id" v-slot="{ active }">
                <a
                 :class="[
                  status.current ? 'font-medium text-gray-900' : 'text-gray-500',
                  active ? 'body__header--menu__item' : '',
                  'rounded mx-0 block px-2 py-1 text-xs cursor-pointer',
                 ]"
                 @click="this.pushSearchStatus(status.name)"
                >
                 <div class="flex justify-between">
                  {{ status.name }}
                  <svg
                   v-show="this.searchStatus.includes(status.name)"
                   xmlns="http://www.w3.org/2000/svg"
                   class="ml-1 h-4 w-4"
                   viewBox="0 0 20 20"
                   fill="currentColor"
                  >
                   <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                   />
                  </svg>
                 </div>
                </a>
               </MenuItem>
               <MenuItem class="">
                <a
                 @click="$emit('clearStatusFilter'), (searchStatus.length = 0)"
                 class="font-normal text-blue-600 text-xs rounded mx-0 block px-2 py-1 cursor-pointer lowercase flex justify-end"
                >
                 clear
                </a>
               </MenuItem>
              </div>
             </MenuItems>
            </transition>
           </Menu>
           <div
            class="inline-flex items-center sm:mt-0 cursor-pointer truncate"
            @click="this.setSorting(header.sort, header.name), (header.isSort = !header.isSort)"
           >
            <span
             v-if="header.isSort"
             class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
            >
             <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5"
             >
              <path
               fill-rule="evenodd"
               d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
               clip-rule="evenodd"
              />
             </svg>
            </span>

            <span
             v-else
             class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
            >
             <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5"
             >
              <path
               fill-rule="evenodd"
               d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
               clip-rule="evenodd"
              />
             </svg>
            </span>
           </div>
          </div>
          <div class="inline-flex" v-if="header.name === 'ticketTable.priority'">
           <Menu as="div" class="mt-0 inline-block text-left">
            <div class="rounded">
             <MenuButton
              class="p-1 group inline-flex justify-center text-sm font-semibold uppercase"
             >
              {{ $t("ticketTable.priority") }}
              <FilterIcon class="flex-shrink-0 ml-1 mt-0.5 h-4 w-4" aria-hidden="true" />
             </MenuButton>
            </div>
            <transition
             enter-active-class="transition ease-out duration-100"
             enter-from-class="transform opacity-0 scale-95"
             enter-to-class="transform opacity-100 scale-100"
             leave-active-class="transition ease-in duration-75"
             leave-from-class="transform opacity-100 scale-100"
             leave-to-class="transform opacity-0 scale-95"
            >
             <MenuItems
              class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-20 px-1"
             >
              <div class="py-1">
               <MenuItem v-for="priority in priorities" :key="priority.id" v-slot="{ active }">
                <a
                 :class="[
                  priority.current ? 'font-medium text-gray-900' : 'text-gray-500',
                  active ? 'body__header--menu__item' : '',
                  'rounded mx-0 block px-2 py-1 text-xs cursor-pointer',
                 ]"
                 @click="this.pushSearchPriority(priority.name)"
                >
                 <div class="flex justify-between capitalize">
                  {{ priority.name }}
                  <svg
                   v-show="this.searchPriority.includes(priority.name)"
                   xmlns="http://www.w3.org/2000/svg"
                   class="ml-1 h-4 w-4"
                   viewBox="0 0 20 20"
                   fill="currentColor"
                  >
                   <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                   />
                  </svg>
                 </div>
                </a>
               </MenuItem>
               <MenuItem>
                <a
                 @click="$emit('clearPriorityFilter'), (searchPriority.length = 0)"
                 class="font-normal text-blue-600 text-xs rounded block px-2 py-1 cursor-pointer lowercase flex justify-end"
                >
                 clear
                </a>
               </MenuItem>
              </div>
             </MenuItems>
            </transition>
           </Menu>
           <div
            :class="[
             header.name === 'ticketTable.updatedDate' ? 'py-3' : 'pr-3 pt-1',
             'inline-flex sm:mt-0 cursor-pointer truncate',
            ]"
            @click="this.setSorting(header.sort, header.name), (header.isSort = !header.isSort)"
           >
            <svg
             v-if="header.sort"
             xmlns="http://www.w3.org/2000/svg"
             :class="[
              header.name === this.activeSorting ? `underline` : '',
              'ml-0 h-5 w-5',
              header.name === this.activeSorting ? `underline` : '',
              'ml-0 h-5 w-5',
             ]"
             viewBox="0 0 20 20"
             fill="currentColor"
            >
             <path
              :d="[
               this.activeSorting === header.name && header.isSort
                ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                : this.activeSorting === header.name && !header.isSort
                ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
              ]"
             />
            </svg>
           </div>
          </div>
         </div>
         <div
          v-else
          class="inline-flex items-center sm:mt-0 cursor-pointer"
          @click="this.setSorting(header.sort, header.name), (header.isSort = !header.isSort)"
         >
          <span :class="[header.name === this.activeSorting ? 'text-gray-700' : '', 'mr-1']">
           {{ $t(header.name) }}
          </span>

          <span
           v-if="header.isSort"
           class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
           >
            <path
             fill-rule="evenodd"
             d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
             clip-rule="evenodd"
            />
           </svg>
          </span>

          <span
           v-else
           class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
           >
            <path
             fill-rule="evenodd"
             d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
             clip-rule="evenodd"
            />
           </svg>
          </span>
         </div>
        </th>
        <th class="p-3 text-sm text-right">
         <Popover as="div" class="mt-0 inline-block">
          <div class="rounded">
           <PopoverButton
            class="rounded border border-none hover:text-gray-500 hover:border-gray-300 p-1 text-xs group inline-flex justify-center items-center font-semibold uppercase"
           >
            <font-awesome-icon icon="bars" class="flex-shrink-0 h-4 w-4" />
           </PopoverButton>
          </div>
          <transition
           enter-active-class="transition ease-out duration-100"
           enter-from-class="transform opacity-0 scale-95"
           enter-to-class="transform opacity-100 scale-100"
           leave-active-class="transition ease-in duration-75"
           leave-from-class="transform opacity-100 scale-100"
           leave-to-class="transform opacity-0 scale-95"
          >
           <PopoverPanel
            class="rounded absolute right-10 mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-20 px-1"
           >
            <div class="py-1" v-for="(header, idx) in tableHeadersSelect" :key="idx">
             <div class="relative flex items-start">
              <div class="flex items-center h-5">
               <input
                @input="removeHeader(header.name, idx)"
                :checked="header.show"
                :id="header.name"
                @focus="selectorIsActive = true"
                @blur="selectorIsActive = false"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                class="focus:ring-green-500 h-4 w-4 body__table-header-filter--text border-gray-300 rounded cursor-pointer"
               />
              </div>
              <div class="ml-3 text-sm">
               <label
                :for="header.name"
                class="font-medium text-gray-700 capitalize cursor-pointer"
                >{{ $t(header.name) }}</label
               >
              </div>
             </div>
            </div>
            <div class="">
             <button
              @focus="selectorIsActive = true"
              @blur="selectorIsActive = false"
              class="text-right font-medium text-gray-700 -mt-1"
              @click="showAllHeaders"
             >
              {{ $t("selectAll") }}
             </button>
            </div>
           </PopoverPanel>
          </transition>
         </Popover>
         <!--  <button
                class="border-2 border-white rounded-xl"
                @click="showHeaderSelector = !showHeaderSelector"
                @blur="showHeaderSelector = false"
                v-show="tablePaginatedData.length > 0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                  />
                </svg>
              </button>
              <transition>
                <div
                  role="menu"
                  tabindex="0"
                  @focus="selectorIsActive = true"
                  @blur="selectorIsActive = false"
                  v-show="showHeaderSelector || selectorIsActive"
                  class="mt-0 inline-block rounded absolute w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-50 right-0 top-10"
                >
                  <fieldset class="space-y-1 p-2">
                    <div
                      class="relative flex items-start"
                      v-for="(header, idx) in tableHeadersSelect"
                      :key="header.name"
                    >
                      <div class="flex items-center h-5">
                        <input
                          @input="removeHeader(header.name, idx)"
                          :checked="header.show"
                          :id="header.name"
                          @focus="selectorIsActive = true"
                          @blur="selectorIsActive = false"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          class="focus:ring-green-500 h-4 w-4 body__table-header-filter--text border-gray-300 rounded"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label
                          :for="header.name"
                          class="font-medium text-gray-700 capitalize"
                          >{{ $t(header.name) }}</label
                        >
                      </div>
                    </div>
                  </fieldset>
                  <div>
                    <button
                      @focus="selectorIsActive = true"
                      @blur="selectorIsActive = false"
                      class="text-right font-medium text-gray-700 -mt-1"
                      @click="showAllHeaders"
                    >
                      select all
                    </button>
                  </div>
                </div>
              </transition> -->
        </th>
       </tr>
      </thead>
      <TicketTableBody
       v-if="tablePaginatedData.length > 0"
       :openOrCloseTicket="openOrCloseTicket"
       :checkStatus="checkStatus"
       :checkStatusColor="checkStatusColor"
       :checkPriority="checkPriority"
       :status="status"
       :formatDate="formatDate"
       :formatDateFrench="formatDateFrench"
       :tablePaginatedData="pagination().querySet"
       :account="account"
       :removedHeaders="removedHeaders"
       :goToDetail="goToDetail"
      />
     </table>
     <PaginationComponent
      :backend_pagination="false"
      :current_page="1"
      :select_rows="selectRows"
      first_page_url="test"
      :from="this.rows * this.page - this.rows + 1"
      :last_page="10"
      last_page_url="data.last_page_url"
      links="data.links"
      next_page_url="data.next_page_url"
      :per_page="5"
      :to="getPageLength()"
      :total="this.tablePaginatedData.length"
      @action="$emit('action', $event)"
      @set-rows="setRows($event)"
      @previousPage="previousPage()"
      @nextPage="nextPage()"
     />
    </div>
   </div>
  </div>
 </div>

 <!-- dialog msg -->
 <ComfirmationDialog
  v-if="this.showMessage"
  :messageToShow="this.messageToShow"
  :subMessageToShow="this.subMessageToShow"
 />
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import {
 CheckCircleIcon,
 ChevronRightIcon,
 MailIcon,
 SortAscendingIcon,
 TicketIcon,
 FilterIcon,
} from "@heroicons/vue/solid";
import {
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 Popover,
 PopoverButton,
 PopoverPanel,
} from "@headlessui/vue";
import { LockOpenIcon, LockClosedIcon } from "@heroicons/vue/outline";

import ComfirmationDialog from "./ConfirmationDialog.vue";
import AskConfirmationDialog from "./AskConfirmationDialog.vue";
import TicketTableBody from "./tickets/TicketTableBody.vue";
import PaginationComponent from "./PaginationComponent.vue";

const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");

const months = [
 "January",
 "February",
 "March",
 "April",
 "May",
 "June",
 "July",
 "August",
 "September",
 "October",
 "November",
 "December",
];

const priorities = [
 { id: 1, name: "basse" },
 { id: 2, name: "normale" },
 { id: 3, name: "haute" },
 { id: 4, name: "critique" },
];

export default {
 name: "TicketTable",
 props: ["tablePaginatedData", "statuses", "searchStatus", "searchPriority"],
 components: {
  PaginationComponent,
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
  SortAscendingIcon,
  FilterIcon,
  ComfirmationDialog,
  AskConfirmationDialog,
  TicketTableBody,
  TicketIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  LockOpenIcon,
  LockClosedIcon,
  Popover,
  PopoverButton,
  PopoverPanel,
 },
 data() {
  return {
   selectorIsActive: false,
   activeSorting: "",
   account,
   months,
   status: [],
   updateTicket: false,
   showMessage: false,
   isSort: false,
   messageToShow: "",
   askConfirmation: false,
   confirmationMessage: "",
   subMessageToShow: "",
   tableHeaders: [
    { name: "ticketTable.subject", sort: "id", isSort: false, show: true },

    {
     name: "ticketTable.createdDate",
     sort: "created_at",
     isSort: false,
     show: true,
    },
    {
     name: "ticketTable.updatedDate",
     sort: "updated_at",
     isSort: false,
     show: true,
    },
    {
     name: "ticket.resolution",
     sort: "estimatedResolutionDate",
     isSort: false,
     show: true,
    },
   ],
   tableHeadersSelect: [
    { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
    {
     name: "ticketTable.createdDate",
     sort: "created_at",
     isSort: false,
     show: true,
    },
    {
     name: "ticketTable.updatedDate",
     sort: "updated_at",
     isSort: false,
     show: true,
    },
    {
     name: "ticket.resolution",
     sort: "estimatedResolutionDate",
     isSort: false,
     show: true,
    },
   ],
   ticketAssignedTo: "",
   showIdx: "",
   showPriority: false,
   assignIndex: null,
   successAssign: true,
   searchStatus: [],
   searchPriority: [],
   showHeaderSelector: false,
   removedHeaders: [],
   tableKey: false,
   priorities,
   page: 1,
   rows: 10,
   selectedRows: null,
   selectRows: [5, 10, 15, 20, 25, 30],
  };
 },
 methods: {
  async openOrCloseTicket(
   status,
   id,
   confirmationOpenMessage,
   confirmationCloseMessage,
   openMessage,
   closeMessage,
   successMsg,
   titleOpen,
   titleClose,
   confirmButton,
   goBack
  ) {
   this.updateTicket = !this.updateTicket;

   let confirmationMessage = confirmationCloseMessage;
   let subMessage = closeMessage;
   let title = titleClose;
   let sendAction = 0;

   if (status == 0) {
    sendAction = 1;
    confirmationMessage = confirmationOpenMessage;
    subMessage = openMessage;
    title = titleOpen;
   }
   // const r = await this.$refs.askConfirmationDialog.show({
   //   title: title,
   //   message: confirmationMessage,
   //   okButton: confirmButton,
   //   cancelButton: goBack,
   //   action: "delete",
   // });
   axios
    .put(
     `${this.$cookie.getCookie("API")}/api/v1/tickets/${id}?customerAccount=${
      this.account
     }&open=${sendAction}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    )
    .then((res) => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", successMsg);
    });
  },
  showAllHeaders() {
   this.tableHeaders.length = 0;
   this.tableHeadersSelect.length = 0;
   this.tableHeaders = [
    { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
    {
     name: "ticketTable.createdDate",
     sort: "created_at",
     isSort: false,
     show: true,
    },
    {
     name: "ticketTable.updatedDate",
     sort: "updated_at",
     isSort: false,
     show: true,
    },
    {
     name: "ticket.resolution",
     sort: "estimatedResolutionDate",
     isSort: false,
     show: true,
    },
   ];
   this.tableHeadersSelect = [
    { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
    {
     name: "ticketTable.createdDate",
     sort: "created_at",
     isSort: false,
     show: true,
    },
    {
     name: "ticketTable.updatedDate",
     sort: "updated_at",
     isSort: false,
     show: true,
    },
    {
     name: "ticket.resolution",
     sort: "estimatedResolutionDate",
     isSort: false,
     show: true,
    },
   ];
   this.removedHeaders.length = 0;
   this.showHeaderSelector = false;
  },
  removeHeader(headerName, idx) {
   let headerIndex = null;
   for (let index = 0; index < this.tableHeadersSelect.length; index++) {
    if (this.tableHeadersSelect[index].name.toLowerCase() === headerName.toLowerCase()) {
     headerIndex = index;
    }
   }
   let matchHeader = undefined;
   for (let index = 0; index < this.tableHeaders.length; index++) {
    if (this.tableHeaders[index].name.toLowerCase() === headerName.toLowerCase()) {
     matchHeader = index;
    }
   }
   if (matchHeader >= 0) {
    this.removedHeaders.push(this.tableHeaders[matchHeader].name);

    this.tableHeadersSelect[headerIndex].show = false;

    this.tableHeaders.splice(matchHeader, 1);
   } else {
    this.tableHeadersSelect[headerIndex].show = true;

    let removeFromRemovedHeaderIndex = this.removedHeaders.indexOf(
     this.tableHeadersSelect[headerIndex].name
    );

    if (removeFromRemovedHeaderIndex !== -1) {
     this.removedHeaders.splice(removeFromRemovedHeaderIndex, 1);
    }
    this.tableHeaders.splice(headerIndex, 0, this.tableHeadersSelect[headerIndex]);
   }
   this.tableKey = !this.tableKey;
  },
  checkIfHeader(headerName) {
   for (let index = 0; index < this.tableHeaders.length; index++) {
    if (this.tableHeaders[index].name === headerName.toLowerCase()) {
     return true;
    } else {
     return false;
    }
   }
  },
  setSorting(sort, header) {
   this.$emit("sorting", sort);
   this.activeSorting = header;
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " at " +
    ("0" + dateCreated.getHours()).slice(-2) +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2);

   return formatedDate;
  },
  formatDateFrench(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " à " +
    ("0" + dateCreated.getHours()).slice(-2) +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2);

   return formatedDate;
  },
  async getStatus() {
   const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/statuses`, {
    headers: {
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   });
   this.status = res.data;
  },
  checkStatus(id) {
   if (this.status) {
    for (let index = 0; index < this.status.length; index++) {
     if (this.status[index].id == id) {
      return this.status[index].name;
     }
    }
   }
  },

  goToDetail(id, status) {
   let statusName = this.checkStatus(status);

   this.$emit("updateTicketDetail", id, statusName);
  },
  pushSearchStatus(status) {
   if (!this.searchStatus.includes(status)) {
    this.searchStatus.push(status);
   } else {
    let index = this.searchStatus.indexOf(status);
    if (index > -1) {
     this.searchStatus.splice(index, 1);
    }
   }
   this.$emit("pushStatusSearch", status);
  },
  pushSearchPriority(priority) {
   if (!this.searchPriority.includes(priority)) {
    this.searchPriority.push(priority);
   } else {
    let index = this.searchPriority.indexOf(priority);
    if (index > -1) {
     this.searchPriority.splice(index, 1);
    }
   }
   this.$emit("pushPrioritySearch", priority);
  },
  checkStatusColor(id) {
   if (this.status) {
    for (let index = 0; index < this.status.length; index++) {
     if (this.status[index].id == id) {
      return this.status[index].color;
     }
    }
   }
  },
  checkPriority(id) {
   if (this.priority) {
    for (let index = 0; index < this.priority.length; index++) {
     if (this.priority[index].id == id) {
      return this.priority[index].name;
     }
    }
   }
  },
  setRows(event) {
   this.selectedRows = event;
  },
  getPageLength() {
   if (this.page === this.pagination().pages) {
    return this.tablePaginatedData.length;
   } else {
    return this.rows * this.page;
   }
  },
  pagination() {
   if (this.selectedRows) {
    this.rows = this.selectedRows;
   }
   let trimStart = (this.page - 1) * parseInt(this.rows);
   let trimEnd = trimStart + parseInt(this.rows);

   let trimmedData = this.tablePaginatedData.slice(trimStart, trimEnd);

   let pages = Math.ceil(this.tablePaginatedData.length / this.rows);

   return {
    querySet: trimmedData,
    pages: pages,
   };
  },
  nextPage() {
   if (this.page < this.pagination().pages) {
    this.page = parseInt(this.page) + 1;
   } else {
    this.page = this.page;
   }
  },
  previousPage() {
   if (this.page > 1) {
    this.page = parseInt(this.page) - 1;
   } else {
    this.page = this.page;
   }
  },
 },
 mounted() {
  this.getStatus();
 },
 computed: {
  ...mapGetters(["active", "language"]),
 },
};
</script>

<style>
.ticket_table_height {
 min-height: 200px;
}
.header_selector_menu {
 right: 0px;
 margin-top: 26px;
 z-index: 100;
}
.priority_selection {
 padding-block-start: 20px;
}
.pagination_button {
 padding: 8px;
 margin: 2px;
 border-radius: 3px;
 font-size: 1em;
 cursor: pointer;
}
html,
body {
 height: 100%;
}

@media (min-width: 640px) {
 table {
  display: inline-table !important;
 }

 thead tr:not(:first-child) {
  height: 50px;
  display: none;
 }
}
@media only screen and (max-width: 640px) {
 td {
  height: 50px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
 }

 th {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  height: 50px;
 }
}
</style>
